
//  bigger than 1400
%header
	font-family: "Open Sans", sans-serif
	font-weight: 700

// NAVIGATION
 
#mobile-navigation
	display: none

.navbar-burger
	display: none

// Overide Bulma stuff
.menu
	font-size: 16px !important
	line-height: 24px !important
 
.navbar-item 
	img 
		max-height: 60px !important 
	.submenuimageblock img
		max-height: 100px !important 
   
.navbar-dropdown
	background: none
	border: none
	box-shadow: none

#mainnavigation
	background-color: $white
	display: flex 
	font-size: 16px
	justify-content: center
	line-height: 24px
	position: fixed	
	top: 0 !important
	width: 100%
	z-index: 999
	.navbar-brand, 
	.navbar-menu
		align-items: center
	.navbar-end
		justify-content: flex-end
		width: 100%
		padding-right: 50px
	.navbar
		display: flex	
		height: 60px
		width: 100%
		// Site Logo
		.navbar-brand
			display: flex
			float: left
			height: 60px
			margin-left: 70px
			width: auto
		img#sitelogo
			height: 40px 
			width: auto			
	#main-navigation
		display: flex
		.active
			border-bottom: 2px solid $orange
			color: $orange
		> .navbar-item
			border-bottom: 2px solid $white
			color: $black
		.thesubnavmenu
			top: -9999px
			position: fixed
		.navbar-item:hover .thesubnavmenu
			background-color: $white !important
			display: block !important
			height: 200px !important
			left: 0
			top: 50px
			width: 100vw
			a
				border-bottom: none !important
				&:hover
					border-bottom: none !important
		.submenuimageblock
			display: flex
			justify-content: space-around
			padding-top: 36px


// Login Button
.loginbutton
	align-items: center
	display: flex
	margin-right: 110px
	.button
		border-radius: 10px
		font-family: "Open Sans", sans-serif
		font-size: 14px
		height: 25px
		margin-left: 10px
		padding: 2px 10px
		width: auto
	.button.dealerlogin
		background-color: $black !important
		&:hover
			background-color: $orange !important


// SUBNAVIGATION
.subnavigation 
	align-items: center 
	display: flex 
	margin-top: 60px 
	padding: 6px
	h3 
		@extend %header
		display: inline-block
		font-size: 16px
		line-height: 16px
		margin-left: 70px
		padding: 0 !important
	ul 
		display: flex
		flex-grow: 1
		font-size: 14px
		justify-content: flex-end
		margin-right: 292px
		padding: 0 !important
		li 
			display: inline-flex
			margin-right: 20px
		li a 
			color: $black
		li.faded a
			color: #7a7a7a                
	.button
		border-radius: 10px
		height: 26px
		position: absolute
		right: 60px
		top: 65px
		width: 107px
// CONTENT

.columns.first
	margin-top: 60px


// HR Below breadcrumbs
.hrbelowbreadcrumbs	
	border: 1px solid $grey-lighter
	margin: 10px 0 0 0
	width: 100%

// DESKTOP - HD
@media (max-width: 1400px)
	.loginbutton
		margin-right: 60px  
	#mainnavigation #main-navigation .submenuimageblock 
		.marginshorttrack
			margin-right: 100px	 
		.marginhelmets
			margin-right: 75px				 
		img 
			max-width: 300px
			height: auto	

// max-width 1200 is widescreen kan er nog tussen

// TABLET - DESKTOP
@media (max-width: 1024px)
	.menu
		font-size: 12px
	#mainnavigation 
		font-size: 12px !important
		.navbar
			justify-content: space-between !important
		#main-navigation li 
			margin-right: 0
			a 
				padding: 0px 0px 2px 6px
		.navbar .navbar-brand
			margin-left: 0px
		a.navbar-item
			font-size: 12px !important
		.navbar-brand
			height: 40px
	.loginbutton 
		margin-right: 12px	
	.navbar-menu
		display: flex !important
		box-shadow: none !important
		margin-left: 0px
	

// TABLET
// breakpoint burger menu. 
@media (max-width: 768px)  
	#main-navigation, .navbar-end, .loginbutton
		display: none !important
	.mobiledealerlogin
		text-align: center !important
	//  Burger Menu
	.navbar-burger
		display: block !important
		height: 40px
		position: absolute
		right: 19px
		width: 40px
		top: 5px
		span 
			color: $orange
			height: 3px
			left: 7px
			position: absolute
			width: 29px
			&:first-child
				top: 9px
			&:nth-child(2)
				top: 16px
			&:nth-child(3)
				top: 23px					
		// Change to X when you click the burger
		&.is-active
			span
				&:first-child
					top: 0px
					transform: translateY(14px) rotate(45deg)
				&:nth-child(3)
					top: 19px
					transform: translateY(-5px) rotate(-45deg)		
		.burgertext
			font-family: "Open Sans", sans-serif
			font-size: 9px
			left: 9px
			line-height: 11px
			position: absolute
			text-transform: none
			top: 28px	
	#mainnavigation 
		position: relative
		.navbar .navbar-brand
			margin-left: 17px
			width: 125px
		.navbar
			display: block
	// Mobile Navigation
	#mobile-navigation
		display: block	
	.navbar-menu 
		background: $black-ter 
		clear: both
		display: block !important
		left: -150%
		margin-top: 60px
		position: relative
		transition: all 0.64s ease-in-out !important
		top: 10px
		width: 0
		z-index: 999
		&.is-active 
			left: 0
			width: 100vw
			margin: 0
			padding: 0
			
	#mobile-navigation 
		#mobile-menu
			font-family: "Open Sans", sans-serif
			left: 0
			padding: 0
			> li 
				padding: 0.75rem 2rem
			li
				display: block
				width: 100vw
				padding: 5px 0
				text-align: center
				a
					color: $white
					display: block
					font-size: 24px
					font-weight: 700
					line-height: 33px
					&:hover
						color: $orange
				.submenu-mobile li a		
					font-weight: 300
					padding: 12px 0		
	.subnavigation
		display: block  !important
		margin-left: 0 
		margin-top: 0 !important
		text-align: center		
		margin-right: 0
		h3 
			display: block
			margin: 0 auto
		ul 
			display: block
			position: relative	
			right: unset
			top: 10px
			width: 100%
	.breadcrumbs 
		display: block
		margin: 0 auto 
		padding-left: 10px !important
		// width: 300px
		.breadcrumb 
			display: block     
			ul
				display: block
				li, a
					display: inline-block
				a
					font-weight: normal

@media (max-width: 450px)
	.columns .subnavigation ul 
		text-align: center
	.breadcrumb 
		ul li a
			font-size: 14px
		.icon 
			margin-left: 5px

			 

  