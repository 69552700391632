//FOOTER
.footer
    background-color: $white-ter
    .footercolumns 
        display: flex
        margin-top: 70px
        justify-content: center
        .column 
            h3    
                font-family: "Open Sans", sans-serif
                font-size: 17px
                font-weight: 700
                text-transform: none
                width: 120px
                color: $grey-dark
            ul li a
                font-family: "Open Sans", sans-serif
                font-size: 16px
                line-height: 23px    
                color: $grey-dark
                &:hover
                    color: $orange
        .thesocialicons .socialicon svg 
            width: 32px 
            height: 32px
            padding: 4px
    .quickmenu
        justify-content: flex-start
        svg
            margin: 0 1px -2px -30px
           
    .socialicons
        justify-content: flex-end

.footermiddle 
    display: block 
    height: 40px
    margin-top: 67px
    padding-left: 200px
    width: 100%
    ul 
        list-style: none 
        li
            display: block 
            float: left
            margin-right: 40px
            a 
                color: $grey-dark
                &:hover
                    color: $orange
.footerabout
    padding: 20px

.footerbottom 
    margin-top: 140px
    padding-bottom: 40px         

@media (max-width: 1400px) 
    .breadcrumbs, .footermiddle
        padding-left: 100px

@media (max-width: 1024px)        
    .breadcrumbs, .footermiddle
        padding-left: 49px 

@media (max-width: 768px)
    .footer .footercolumns
        flex-direction: column 
        margin-top: 10px
        .column 
            display: block
            width: 100%
            text-align: center
            h3 
                width: auto
            ul li a 
                line-height: 40px
    .socialicons h3 
        display: block
    .thesocialicons
        display: flex
        justify-content: space-evenly
        padding-top: 20px
    .footermiddle
        // no content
        height: 0
        ul li 
            display: block 
            width: 100%
            text-align: center
            padding: 10px   
    .footerbottom 
        // no content
        margin-top: 0
    nav.breadcrumb 
        height: 40px
        display: block
        margin: 0 !important
        padding: 5px 0 !important
        background-color: $white
        a 
            padding: 5px
        ul 
            justify-content: center
            li, li a
                display: inline-block
                