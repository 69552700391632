@charset "utf-8"
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400|Gelasio:400,600,700|Roboto+Condensed:400,700&display=swap")

$black-bis: hsl(0, 0%, 7%) !default
$black-ter: hsl(0, 0%, 14%) !default
$black: #0d0c0b
$blue: #00A8B0
$dblue: #0961ad
$cyan: hsl(204, 86%, 53%) !default
$dgray: #666666
$dorange: #ed5800
$gray: #878787
$silver: #878787
$green: hsl(141, 71%, 48%) !default
$grey-dark: hsl(0, 0%, 29%) !default
$grey-darker: hsl(0, 0%, 21%) !default
$grey-light: hsl(0, 0%, 71%) !default
$grey-lighter: hsl(0, 0%, 86%) !default
$grey: hsl(0, 0%, 48%) !default
$lgray: #b5b5b5
$morange: #ff6910
$orange: #fd6705
$lorange: lighten($orange, 6)
$purple: hsl(271, 100%, 71%) !default
$red: #ff0000 !default
$turquoise: hsl(171, 100%, 41%) !default
$white-bis: hsl(0, 0%, 98%) !default
$white-ter: hsl(0, 0%, 96%) !default
$white: hsl(0, 0%, 100%) !default
$dwhite: #e6e6e6 !default
$yellow: hsl(48, 100%, 67%) !default

$link-hover: $orange

$scheme-main: $white 
$scheme-main-bis: $white-bis
$scheme-main-ter: $white-ter 
$scheme-invert: $black 
$scheme-invert-bis: $black-bis 
$scheme-invert-ter: $black-ter 

$background: $white 
$link: $orange
$link-hover: $orange
$link-focus: $orange
$link-active: $orange

$column-gap: 0

$size-1: 3.25rem
$size-2: 3rem
$size-3: 2.5rem
$size-4: 2rem
$size-5: 1.5rem
$size-6: 1.25rem
$size-7: 1rem

$family-sans-serif: "Open Sans", sans-serif
$family-primary: $family-sans-serif
 
@import navigation
@import footer

body 
    width: 100%
    padding: 0
    margin: 0
 
// headers
.roboto 
    font-family: "Roboto Condensed", sans-serif
 
// main text
.opensans
    font-family: "Open Sans", sans-serif
// italics foto credits etc
.lora
    font-family: "Lora", serif
    text-transform: none !important

.gelasio
    font-family: "Gelasio", serif
    text-transform: none !important
 
p 
    font-family: "Open Sans", sans-serif
    font-size: 16px
    line-height: 32px
    margin-bottom: 16px

*:focus 
    outline: none !important
    border: none !important

// h6 starts at $base-font-size 
// headings grow from h6 to h1 by $heading-scale
$base-font-size: 18px
$heading-scale: 8 // amount of px headings grow from h6 to h1

@for $i from 1 through 6  
  h#{$i}  
    font-family: "Open Sans", sans-serif
    font-size: $base-font-size + $heading-scale * (6 - $i)
    line-height: ($base-font-size + $heading-scale * (6 - $i)) * 2
 
.has-margin-top-30
    margin-top: 30px !important
.has-margin-bottom-30
    margin-bottom: 30px !important
.has-padding-top-30
    padding-top: 30px !important
.has-padding-bottom-30
    padding-bottom: 30px !important

button.button, a.button
    background: $black !important
    border: none
    border-radius: 4px 
    color: $white !important
    cursor: pointer
    display: inline-block !important
    font-size: 18px
    font-weight: 500
    height: 50px 
    line-height: 1.5
    padding: 10px 16px
    &:hover 
        background: $orange !important

// BREADCRUMBS
.breadcrumb  
    height: 40px 
    padding: 20px 200px  
    a 
        font-size: 16px
        padding: 16px

.biggerthan
    font-family: "Open Sans", sans-serif
    padding-right: 10px
 
